<template>
  <div id="addAdviceDialog">
    <el-dialog
      title="新增反馈"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="80px"
      >
        <el-form-item label="联系人" prop="contactPerson">
          <el-input
            v-model="addForm.contactPerson"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactNumber">
          <el-input
            v-model="addForm.contactNumber"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="反馈类型" prop="adviceTypeId">
          <el-select
            v-model="addForm.adviceTypeId"
            placeholder="请选择反馈类型"
          >
            <el-option
              :label="item.adviceName"
              :value="item.adviceTypeId"
              v-for="item of adviceTypeList"
              :key="item.adviceTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈内容" prop="adviceText">
          <el-input
            type="textarea"
            v-model="addForm.adviceText"
            placeholder="请输入反馈内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input
            v-model="addForm.orderId"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="停车场">
          <el-select
            v-model="addForm.parkingLotName"
            placeholder="请选择停车场"

          >
            <el-option
              :label="item.parkingLotName"
              :value="item.parkingLotName"
              v-for="item of parkingList"
              :key="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实际支付">
          <el-input
            v-model="addForm.payAmount"
            placeholder="请输入实际支付金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input
            v-model="addForm.plateNum"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="应支付">
          <el-input
            v-model="addForm.totalPrice"
            placeholder="请输入应支付金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            ref="imgUpload"
            :action="BASE_URL + 'file/test'"
            :headers="{ ['Authorization']: 'Bearer ' + token }"
            :on-success="imgUploadSuccess"
            :on-remove="imgUploadRemove"
            multiple
            :file-list="imgUploadList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList", "adviceTypeList", "parkingList"],
  data() {
    return {
      show: false,
      loading: false,
      addForm: {
        contactPerson: "",
        adviceTypeId: "",
        adviceText: "",
        contactNumber: "",
        orderId: "",
        parkingLotName: "",
        payAmount: "",
        plateNum: "",
        totalPrice: "",
        advicePictureList: [],
      },
      addFormRules: {
        contactPerson: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contactNumber: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        adviceText: [
          { required: true, message: "请输入反馈内容", trigger: "blur" },
        ],
        adviceTypeId: [
          { required: true, message: "请选择反馈类型", trigger: "change" },
        ],
        parkingLotName: [
          { required: true, message: "请选择停车场", trigger: "change" },
        ],
        payAmount: [
          { required: true, message: "请输入实际支付金额", trigger: "blur" },
        ],
        plateNum: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
        totalPrice: [
          { required: true, message: "请输入应支付金额", trigger: "blur" },
        ],
      },
      imgUploadList: [],
      BASE_URL:process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    imgUploadSuccess(res, file) {
      if (res.code === 0) {
        this.addForm.advicePictureList.push(res.data.picUrl);
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.msg);
        let uploadFiles = this.$refs.imgUpload.uploadFiles;
        uploadFiles.forEach((item, index) => {
          if (item.name === file.name) {
            uploadFiles.splice(index, 1);
          }
        });
      }
    },
    imgUploadRemove(file) {
      let advicePictureList = this.addForm.advicePictureList;
      advicePictureList.forEach((item, index) => {
        if (item === file.response.data.picUrl) {
          advicePictureList.splice(index, 1);
        }
      });
    },
    reset() {
      this.$set(this, "addForm", {
        contactPerson: "",
        adviceTypeId: "",
        adviceText: "",
        contactNumber: "",
        orderId: "",
        parkingLotName: "",
        payAmount: "",
        plateNum: "",
        totalPrice: "",
        advicePictureList: [],
      });
      this.$refs["addForm"].resetFields();
      this.$refs.imgUpload.clearFiles();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/advice/add", {
          ...this.addForm,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addAdviceDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
